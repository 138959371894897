/*

.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/
@font-face {
  font-family: 'GT-Zirkon-Book';
  src: url("./fonts/GT-Zirkon-Book.otf") format("opentype"),
  url('./fonts/GT-Zirkon-Book.woff2') format('woff2'),
  url('./fonts/GT-Zirkon-Book.woff2') format('woff'),
  url("./fonts/GT-Zirkon-Book.ttf") format("ttf");
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'GT-Zirkon-Book','PT Sans', sans-serif;
  background-color: #f8ff00;
}
@media only screen and (max-width: 768px) {
  p.title-text {
    font-size: 5vh!important;
  }
  * {
    font-size: 3.4vh!important;
  }
}
p.title-text {
  width: 100%;
  font-family: 'zirkon','PT Sans', sans-serif;
  font-weight: bold;
  font-size: 2.7vmin;
  padding: 1% 0% 1% 0%;
  margin-top: 5%;
}
p.with-padding {
  padding-left: 5%;
}
.title-text:after {
  content: "";
  display: block;
  height: 1.5vh;
  width: 100%;
  background: url("./components/images/border_line.svg") center center/cover no-repeat;
}
a{
  font-family: 'zirkon','PT Sans', sans-serif;
  color: black;
  text-decoration: none;
}
a:hover {
  font-weight: bold;
}

/*
.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/