div.slideshow-container {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: inherit;
}
img.slideshow {
    width: 80%;
    margin: 0% 10% 0% 10%;
    background-color: inherit;
    height: 100%;
}
div.slideshow-image-container {
    width: 100%;
    height: 100%;
    z-index: -1;
}
div.slideshow-arrow {
    height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
    z-index: 1;
    background-color: unset;
}
div.slideshow-backarrow {
    position: absolute;
    left: 0;
    background-color: unset;
}
div.slideshow-nextarrow {
    right: 0;
}
div.arrow-container {
    height: 100%;
    width: 100%;
    object-fit: contain;
    z-index: 50;
    background-repeat: no-repeat;
    background-position: center;
    background-color: unset;
    opacity: 0;
}
div.arrow-container:hover{
    opacity: 1;
}
img.img-right-arrow{
    background-color: unset;
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-left: 85%;
}
img.img-left-arrow{
    background-color: unset;
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-right: 85%;
}
@media only screen and (max-width: 768px) {
    div.slideshow-container {
        height: 90%;
        width: 100vw;
        position: fixed;
        margin-top: 15vh;
        background-color: inherit;
    }
    img.img-right-arrow{
        width: 12vw;
        margin-left: 35vw;
        margin-top: 37vh;
    }
    img.img-left-arrow{
        width: 12vw;
        margin-left: 2vw;
        margin-top: 37vh;
    }
    div.arrow-container {
        opacity: 1;
    }
}
