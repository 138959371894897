.home-container {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}
.right-container{
    height: 93vh;
    width: 50%;
    position: fixed;
    z-index: 1;
    overflow-x: hidden;
    right: 0;
    background-color: white!important;
}

#home-right {
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 100% 90%;
}

#home-left {
    /*background: url("../images/Kuori_Logo Form_V6.svg") center center/cover no-repeat;*/
    background-size: contain;
}

.left-container {
    height: 93%;
    width: 50%;
    position: fixed;
    z-index: 999;
    overflow-x: hidden;
    left: 0;
}

#home-left.active {
    background-color: #f8ff00;
}
#home-right.active {
    background-color: #f8ff00;
}
#homeStart.left-container.active{
    background-color: #f8ff00;
    mask-image: linear-gradient(to top,transparent 0%, #f8ff00 15%, #f8ff00 85%, transparent 100%);
}
.active {
    background-color: #f8ff00!important;
    z-index: 998;
}
.inactive {
    background-color: #FFF!important;
}
.title-down{
    position: fixed;
    bottom: 0;
    margin: 1%;
    font-family: 'zirkon','PT Sans', sans-serif;
    font-weight: bold;
    font-size: 2.7vmin;
    color: black;
    background-color: transparent;
    text-transform: uppercase;
    z-index: 999;
}
.animate-left {
    height: 93%;
    width: 50%;
    position: fixed;
    z-index: 1;
    overflow-x: hidden;
    left: 0;

    animation: menueleftanimation 2s ease-out forwards;
}
@keyframes menueleftanimation {
    0% {
        left: 0%;
    }
    100% {
        left: 50%;
        width: 25%;
        background: none;
        background-color: #fdffbc;
    }
}
.animate-right{
    height: 93%;
    width: 50%;
    position: fixed;
    z-index: 1;
    overflow-x: hidden;
    right: 0;
    background-color: white!important;

    animation: menuerightanimation 2s ease-out forwards;
}
@keyframes menuerightanimation {
    0% {
        left: 50%;
    }
    100% {
        background: none;
        left: 75%;
        width: 25%;
    }
}
.animateBack-left {
    height: 93%;
    width: 50%;
    position: fixed;
    z-index: 1;
    overflow-x: hidden;
    left: 0;

    animation: menueleftanimationback 2s ease-out forwards;
}
@keyframes menueleftanimationback {
    0% {
        left: 50%;
        width: 25%;
        background-color: #fdffbc;
    }
    100% {
        left: 0%;
        background-color: #f8ff00;
    }
}
.animateBack-right{
    height:93%;
    width: 50%;
    position: fixed;
    z-index: 1;
    overflow-x: hidden;
    right: 0;

    animation: menuerightanimationback 2s ease-out forwards;
}
.mobile-home-button{
    display: none;
}
#mobile-productcycle{
    display: none;
}
@keyframes menuerightanimationback {
    0% {
        left: 75%;
        width: 25%;
    }
    100% {
        left: 50%;
    }
}
#desktopHome{
    display: none;
    height: 85vh;
    width: 23%;
    margin-top: 7vh;
}
#desktopHome.title-down{
    bottom: 0;
    position: fixed;
    padding-top: 82vh;
}
@media only screen and (max-width: 768px) {
    .right-container {
        display: none;
    }
    .left-container {
        width: 100vw!important;
        height: 100vh!important;
        z-index: 0!important;
        display: block;
        background-color: #f8ff00!important;
    }
    .mobile-home-button{
        display: block;
        height: 8vh;
        background-color: white;
        bottom: 0;
        padding-left: 5%;
        font-family: 'zirkon','PT Sans', sans-serif;
        font-weight: bold;
        font-size: 2.7vmin;
        color: black;
        text-transform: uppercase;
        z-index: 900;
        width: 100vw;
        position: fixed;
    }

    #mobile-productcycle{
        display: none;
        position: fixed;
        width: 100vw;
        height: 100vh;
        margin-bottom: 8vh;
        bottom: 0;
        background-color: white;
        z-index: 800;
    }
    #mobile-start-arrow{
        float: right;
        width: 25vw;
        height: 7vmax;
        margin-top: 1vh;
        margin-right: 3vw;
        right: 0;
        bottom: 0;
        position: fixed;
    }
    .animation-icon-mobile-menu-arrow{
        animation: iconAnimationArrow 2s ease forwards;
    }
    @keyframes iconAnimationArrow {
        0% {
        }
        25%{
            bottom: -7vh;
        }
        100% {
        }
    }
    .mobile-start-arrow-startimage{
        background: url("../images/arrow_down.svg") no-repeat!important;
        background-position: right!important;
    }
    .mobile-start-arrow-backImage{
        background: url("../images/close12.svg") no-repeat;
        background-position: right!important;
    }
    text.mobile-button-text{
        position: fixed;
        margin-top: 5%;
        background-color: inherit;
        font-family: 'zirkon','PT Sans', sans-serif;
    }

    .start-animation-productcycle{
        animation: startanimation 1s ease-out forwards;
    }

    .back-animation-productcycle{
        animation: backanimation 1s ease-out forwards;
    }

    @keyframes backanimation {
        0% {
            height: 75vh;
            width: 100vw;
        }
        100% {
            height: 0vh;
        }
    }
    @keyframes startanimation {
        0% {
            width: 100vw;
            height: 0vh;
        }
        100% {
            height: 75vh;
            width: 100vw;
        }
    }
    .animate-left {
        animation: none!important;
    }
    .animate-right {
        animation: none!important;
    }
    .animateBack-left {
        width: 100vw!important;
    }
    h1.title-down {
        display: none;
    }
    .animateBack-right {
        display: none;
    }
    #homeStart.left-container.active{
        mask-image: linear-gradient(to top,transparent 0%, #f8ff00 5%, #f8ff00 90%, transparent 100%)!important;
    }
}