@charset "utf-8";
* {
    box-sizing: border-box;
}
#aboutStart {
    animation: startanimation 3s ease-out both;
    mask-image: linear-gradient(to top,transparent 0%, #f8ff00 15%, #f8ff00 85%, transparent 100%);
}
#aboutStart.no-overlay {
    mask-image: none;
}
.about-content {
    width: 100%;
    margin-bottom: 10%;
}
/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
*{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
@keyframes startanimation {
    0% {
        height: 0%;
    }
    66% {
        height: 0%;
    }
    100% {
        top: 0;
        height: 100%;
    }
}
.cards {
    padding: 4rem;
    background: #fff;
}
.team-item-picture {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 99%;
    max-height: 100%;
    object-fit: cover;
}

.team-item-picture:hover {
    transform: scale(1.1);
}
.aboutData-highlight {
    font-family: 'zirkon','PT Sans', sans-serif;
    font-weight: bold;
    font-size: 2.7vmin;
    margin: 3% 0% 1% 4%;
    width: 75%;
    display: flex;
    float: left;
}
.aboutData-highlight-Name {
    font-family: 'zirkon','PT Sans', sans-serif;
    font-weight: bold;
    font-size: 2.7vmin;
    margin: 1% 0% 0% 4%;
    width: 75%;
    display: block;
    float: left;
}
.aboutData-text {
    font-family: 'GT-Zirkon-Book','PT Sans', sans-serif;
    font-size: 2.2vmin;
    margin: 0% 0% 1% 0%;
    line-height: 1.3;
    width: 75%;
    font-weight: normal;
}
p.aboutData-highlight {
    font-family: 'zirkon','PT Sans', sans-serif;
    font-weight: bold;
    font-size: 2.7vmin;
    margin: 1% 0% 1% 4%;
    width: 75%;
}
p.aboutData-text {
    font-family: 'GT-Zirkon-Book','PT Sans', sans-serif;
    font-size: 2.2vmin;
    margin: 1% 0% 4% 4%;
    line-height: 1.3;
    width: 75%;
}
div.team-item-info {
    margin: 2% 0% 0% 0%;
    width: 100%;
    display: flex;
}
div.donation-title {
    width: 100%;
    position: relative;
    font-family: 'zirkon','PT Sans', sans-serif;
    font-size: 2.7vmin;
    padding: 10% 0% 1% 4%!important;
    font-weight: bold;
}
div.donation-title:after {
    content: "";
    display: block;
    height: 0.75vh;
    width: 75%;
    background: url("../images/border_line.svg") center center/cover no-repeat;
}

div.teamData-title {
    font-family: 'zirkon','PT Sans', sans-serif;
    font-weight: bold;
    /*font-size: 1.5vmax;
     */
    font-size: 2.7vmin;
    position: relative;
    margin: 1% 0% 1% 4%;
    width: 100%;
}
div.teamData-title:after {
    content: "";
    height: 0.75vh;
    width: 100%;
    background: url("../images/border_line.svg") center center/cover no-repeat;
}
.project-div{
    width: 100%;
    display: flex;
}

@media only screen and (max-width: 768px) {
    p.aboutData-highlight {
        font-size: 2.5vmax!important;
    }
    div.donation-title {
        margin-top: 5%;
        font-size: 2.5vmax!important;
    }
    p.aboutData-text {
        font-size: 2.2vmax!important;
        line-height: 1.2!important;
    }
    p.team-highlight {
        font-size: 2.5vmax!important;
    }
    p.team-text {
        font-size: 2.2vmax!important;
    }
    div.Partners{
        margin-bottom: 30%;
    }
    span.aboutData-highlight {
        font-size: 2.5vmax!important;
    }
    .aboutData-highlight-Name {
        font-size: 2.5vmax!important;
    }
    .aboutData-text {
        font-size: 2.2vmax!important;
        width: 75%;
    }
    div.teamData-title {
        font-size: 2.5vmax!important;
    }
    .foot {
        content: "";
        width: 100%;
        height: auto;
        margin-bottom: 25%!important;
    }
    #aboutStart {
        animation: none;
    }
}