#contactstart {
    position: fixed;
    animation: startanimation 2s ease-out forwards;
    mask-image: linear-gradient(to top,transparent 0%, #f8ff00 15%, #f8ff00 85%, transparent 100%);
}
@keyframes startanimation {
    0% {
        height: 0%;
    }
    50% {
        height: 0%;
    }
    100% {
        /* If height is set to 100% creates a non reachable text part in About.js
        height: 100%;
         */

    }
}
@media only screen and (max-width: 768px) {
    div.contact-information {
        display: block!important;
    }
    div.content {
        /*width: 60%!important;*/
        /*margin-left: 5%;*/
        height: 120vh!important;
        padding: 0;
        margin-bottom: 30% !important;
    }
    div.border {
        display: none;
    }
    div.social-media {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    div.socialmedia-entry {
        width: 25% !important;
        margin-right: 8% !important;
        text-align: left!important;
        font-size: 2.2vmin;
        margin-bottom: 7% !important;
    }
    div.socialmedia-entry-last {
        width: 25% !important;
        margin-right: 42% !important;
        text-align: left!important;
        font-size: 2.2vmin;
        margin-bottom: 5% !important;
    }
    div.logo-description{
        font-size: 2.2vmax!important;
    }

    div.contact-title {
        font-size: 2.5vmax!important;
    }
    div.contact-text{
        font-size: 2.5vmax!important;
    }
    div.contactData-title {
        font-size: 2.5vmax!important;
    }
    div.firstcontactData-title {
        font-size: 2.5vmax!important;
    }
    div.contactData-text {
        font-size: 2.2vmax!important;
    }
    .contact-address {
        font-size: 2.2vmax!important;
        max-width: 75%!important;
    }

    p.title-text{
        font-size: 2.5vmax!important;
    }
    div.contact-container {
        width: 100%!important;
        margin-bottom: 6vh;
    }
    #contactstart {
        animation: none;
    }
    div.contact-address-container{
        margin-top: 0.5vh!important;
    }
}
div.content {
    margin-top: 20%;
    max-width: 75%;
    height: 100vh;
}
div.contact-container {
    float: left;
    width: 37.5%;
}
div.border {
    width: 25%;
}
div.contact-title {
    position: relative;
    font-family: zirkonBold;
    font-size: 2.7vmin;
    text-transform: uppercase;
    padding: 1% 15% 1% 5%;
}
div.contact-text{
    font-family: 'GT-Zirkon-Book';
    font-size: 2.2vmin;
    padding: 1% 15% 1% 5%;
    margin-bottom: 10%;
    color: black;
    line-height: 1.3;
}
div.contact-title:after {
    content: "";
    display: block;
    height: 0.75vh;
    width: 100%;
    background: url("../images/border_line.svg") center center/cover no-repeat;
}
div.contactData-title {
    font-family: zirkonBold;
    text-transform: uppercase;
    font-size: 2.7vmin;
    position: relative;
    border-bottom: solid black;
    margin: 1% 15% 1% 5%;

}
div.firstcontactData-title {
    font-family: zirkonBold;
    text-transform: uppercase;
    font-size: 2.7vmin;
    cursor: pointer;
    margin: 1% 15% 1% 5%;
    position: relative;
    /*border-top: solid black;*/
    border-bottom: solid black;
}
div.contactData-text {
    font-family: 'GT-Zirkon-Book';
    padding: 1% 15% 1% 5%;
    font-size: 2.2vmin;
    line-height: 1.3;
}
div.contact-options {
    margin-bottom: 5%;
}
span.contact-address {
    font-size: 2.2vmin;
    max-width: 34%;
}
p.contact-address {
    font-size: 2.2vmin;
    line-height: 2.8vh;
    font-family: 'GT-Zirkon-Book','PT Sans', sans-serif!important;
}
div.contact-information {
    display: flex;
    padding-left: 5%;
    font-family: "GT-Zirkon-Book";
    justify-content: space-between;
    margin-bottom: 10%;
}
div.social-media {
    display: flex;
    justify-content: space-between;

    flex-wrap: wrap;
    margin-top: 2vh;
}
div.socialmedia-entry {
    text-align: center;
    font-size: 2.2vmin;
    margin-bottom: 7%;
    width: 30%;
    margin-right: 6%;
}
div.socialmedia-entry-last {
    text-align: center;
    font-size: 2.2vmin;
    width: 30%;
    margin-right: 0%;
    margin-bottom: 5%;
}
div.contact-address-container{
    margin-top: 2vh;
}


