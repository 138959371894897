@charset "utf-8";
span.clicked{
    background: url("../images/arrow_up.svg") center center/cover no-repeat;
}
span.unclicked {
    background: url("../images/arrow_down.svg") center center/cover no-repeat;
}
#homeStart {
    animation: startanimation 3s ease-out forwards;
}
#homeStart.no-overlay {
    mask-image: none!important;
}
a.link-underline{
    text-decoration: underline;
}
@keyframes startanimation {
    0% {
        height: 0%;
    }
    66% {
        height: 0%;
    }
    100% {
        height: 100%;
    }
}
@keyframes imageAnimationIN {
    0% {
        left: 0%;
        height: 100%;
    }
    100% {
        left: 50%;
        height: 100%;
    }
}
@keyframes imageAnimationOUT {
    0% {
        left: 50%;
        height: 100%;
    }
    100% {
        left: 0%;
        height: 100%;
    }
}
.background-left-side{
    background-color: #f8ff00;
    z-index: 999;
    width: 50%;
    height: 100%;
}
#image-container-journal {
    position: fixed;
    top: 0;
    width: 50%;
    left: 0%;
    background-color: #f8ff00;
}
#image{
    /* Fill borders black*/
    /*width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;*/
    vertical-align: center;
}
.flex{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #f8ff00;
}
.flex img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.image-container-show {
    z-index: 800;
    animation: imageAnimationIN 1s ease-out forwards;
}

.image-container-hide {
    z-index: 800;
    animation: imageAnimationOUT 1s ease-out forwards;
}
div > span.arrow {
    position: absolute;
    right: 0;
    background-size: contain;
    min-height: 100%;
    width: 5%;
    order: 3;
}
div.journalEntry {
    margin-top: 15%;
    width: 100%;
}
#interaktion {
    float: right;
    width: 25%;
    height: 3vmax;
    display: flex;
    background: url("../images/arrow_right.svg") no-repeat;
    background-position: bottom!important;
}
.arrow-active {
    animation:spinback 0.5s linear forwards;
}
.arrow-inactive {
    animation:spin 0.5s linear forwards;
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(180deg);
        transform:rotate(180deg);
    }
}
@keyframes spinback {
    0%{
        -webkit-transform: rotate(180deg);
        transform:rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform:rotate(360deg);
    }
}
div.journalData-title {
    font-family: 'zirkon','PT Sans', sans-serif;
    font-weight: bold;
    /*font-size: 1.5vmax;
     */
    font-size: 2.7vmin;
    position: relative;
    margin: 1% 0% 1% 4%;
    flex-direction: row;
    display: flex;
    width: 100%;
}
div.journalData-title-line {
    content: "";
    display: block;
    height: 0.75vh;
    width: 75%;
    margin-left: 4%;
    background: url("../images/border_line.svg") center center/cover no-repeat;
}
div.journalEntryText {
    width: 75%;
    float: left;
}
span.journalData-title {
    font-family: 'zirkon','PT Sans', sans-serif;
    font-weight: bold;
    /*font-size: 1.5vmax;
     */
    font-size: 2.7vmin;
    float: left;
    width: 52.5%;
    /*border-bottom: solid black;*/
}
span.journalData-date{
    font-family: 'zirkon','PT Sans', sans-serif;
    font-weight: bold;
    display: flex;
    align-items: end;
    justify-content: end;
    text-align: right;
    font-size: 2.7vmin;
    float: right;
    width: 22.5%;
    /*border-bottom: solid black;*/
}
p.journalData-highlight {
    font-family: 'zirkon','PT Sans', sans-serif;
    font-weight: bold;
    font-size: 2.7vmin;
    margin: 3% 0% 2% 5%;
}
p.journalData-text {
    font-family: 'GT-Zirkon-Book','PT Sans', sans-serif;
    font-size: 2.2vmin;
    margin: 1% 0% 20% 5%;
    line-height: 1.3;
}
a.journalData-text {
    font-family: 'GT-Zirkon-Book','PT Sans', sans-serif;
    font-size: 2.2vmin;
    color: black;
    text-decoration: none;
    line-height: 1.3;
}
div.journalImages {
    /*display: none; /* Da zurzeit keine Bilder angezeigt werden sollen */
    background-color: green;
    height: 100%;
    width: 10%;
    z-index: 800;
    position: absolute;
    right: 0%;
    top: 0;
    background: url("../images/arrow_right.svg") center center/contain no-repeat;
}
div.open-journalImages {
    /*display: none; /* Da zurzeit keine Bilder angezeigt werden sollen */
    background-color: green;
    height: 100%;
    width: 10%;
    z-index: 800;
    position: absolute;
    right: 0;
    right: 0;
    top: 0;
    background: url("../images/arrow_left.svg") center center/contain no-repeat;
}
span.rotatedText{
    top: 40%;
    right: 20%;
    font-size: 2.7vmin;
}
div.hidden {
    display: none;
}
#interaktion.hidden {
    display: none;
}

.image-container-mobile{
    display: none;
}

a.colorChange:hover{
    color: #D8D8D8;
}

@media only screen and (max-width: 768px) {

    #interaktion{
        height: 7vmax!important;
        background-position: right!important;
        margin-right: 2vw;
    }
    .image-container-not-mobile{
        display: none;
    }
    .image-hide {
        display: none;
    }
    .image-default{
        display: none;
    }
    div.journalImages {
        /*display: none; /* Da zurzeit keine Bilder angezeigt werden sollen */
        background-color: green;
        height: 100%;
        width: 10%;
        z-index: 800;
        position: absolute;
        right: 2%;
        top: 0;
        background: url("../images/arrow_right.svg") center center/contain no-repeat;
    }
    span.rotatedText{
        transform: rotate(270deg);
        display: inline-block;
        position: absolute;
        top: 45%;
        left: 40%;
        font-size: 2.5vmax!important;
    }
    .image-container-mobile{
        display: none; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        /*padding-top: 100px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: black;
        z-index: 999;
    }

    .modal-content {
        margin: auto;
        display: block;
        /*width: 100%;*/
        max-width: 100%;
    }
    #caption {
        margin: auto;
        display: block;
        width: 80%;
        max-width: 100%;
        text-align: center;
        color: #ccc;
        padding: 10px 0;
        height: 150px;
    }
    .modal-content, #caption {
        -webkit-animation-name: zoom;
        -webkit-animation-duration: 0.6s;
        animation-name: zoom;
        animation-duration: 0.6s;
    }

    @-webkit-keyframes zoom {
        from {-webkit-transform:scale(0)}
        to {-webkit-transform:scale(1)}
    }

    @keyframes zoom {
        from {transform:scale(0)}
        to {transform:scale(1)}
    }

    /* The Close Button */
    .close {
        min-height: 5vh;
        min-width: 5vh;
        background-image: url("../images/close2.svg");
        background-size: cover;
        background-color: initial;
        z-index: 999;
        position: fixed;
        top: calc(2vh);
        left: calc(85vw);
        transition: 0.3s;
    }
    div.journalData-title {
        font-size: 2.5vmax!important;
    }
    span.journalData-title {
        font-size: 2.5vmax!important;
    }
    span.journalData-date{
        font-size: 2.5vmax!important;
    }
    p.journalData-highlight {
        font-size: 2.5vmax!important;
    }
    p.journalData-text {
        font-size: 2.2vmax !important;
        margin-bottom: 35% !important;
        line-height: 1.2!important;
    }
    a.journalData-text {
        font-size: 2.2vmax!important;
    }
    #homeStart {
        animation: none;
    }
}