*{
    /*transition: all 1s ease-out;*/
}
.order1 {
    order: 1;
    transition: all 2s ease-out;
}
.order2 {
    order: 2;
    transition: all 2s ease-out;
}
.order3 {
    order: 3;
    transition: all 2s ease-out;
}
.order4 {
    order: 4;
    transition: all 2s ease-out;
}
.order5 {
    order: 5;
}
.order6 {
    order: 6;
}
.mobile-menu {
    display: none;
}

.navbar-container {
    display: flex;
    flex-direction: row;
    height: 7vh;
    width: 100%;
    transition: all 2s ease-out;
}
a#home {
    display: none;
}
div#slide-show{
    background-color: inherit;
}
.not-visible{
    display: none;
}
.visible{
    display: inherit!important;
}
.animation-container{
    height: 100%;
    width: 100%;
    padding: 10% 10% 10% 10%;
    background-color: inherit;
}
svg{
    background-color: transparent;
}

@media only screen and (max-width: 768px) {
    #closeButton {
        display:none!important;
    }
    #topButton {
        right: 1vw!important;
        left: unset!important;

    }
    .mobile-menu {
        display: block;
        height: 12vh;
        background-color: transparent;
        animation: startanimation 1s ease-out forwards!important;
    }
    .mobile-header-pic {
        /*
        background: url("./images/Kuori_Logo Form_V6.svg") left/contain no-repeat;
         */
        width: 30%;
        height: 100%;
    }
    i#mobile-menu {
        display: block;
        position: fixed;
        top: 3vh;
        right: 3vw;
        width: 10vw;
        height: 10vw;
        cursor: pointer;
        z-index: 900;
        font-size: 10vmin;
    }
    .animation-icon-mobile-menu{
        animation: iconAnimationMenu 1s ease forwards;
    }
    @keyframes iconAnimationMenu {
        0% {
        }
        25%{
            top: -7vh;
        }
        100% {
        }
    }
    button#home {
        background-color: #f8ff00;
        order: 1;
        color: black;
        text-align: left!important;
    }
    button#about {
        order: 2;
        color: black;
        text-align: left!important;
    }

    button#journal {
        order: 3;
        color: black;
        text-align: left!important;
    }
    button#donation {
        order: 4;
        color: black;
        text-align: left!important;
    }
    button#contact {
        display: block;
        text-align: left!important;
        order: 5;
    }
    /*[class^="btn-"] { /* Selector for all classes starting with "btn-" */
    button {
        text-transform: uppercase;
        font-size: 2.7vmin;
        font-family: 'zirkon','PT Sans', sans-serif;
        font-weight: bold;
        text-align: center!important;
        width: 100vw!important;
        height: 9vh!important;
        animation: none!important;
        color: black;
    }
    .btn-expanded {
        background-color: white!important;
    }
    .btn-1-collapsed {
        background-color: white!important;
    }
    .btn-2-collapsed {
        background-color: #f8ff00!important;
    }
    .btn-3-collapsed {
        background-color: white!important;
    }
    .btn-4-collapsed {
        background-color: #f8ff00!important;
    }
    .btn-1-default {
        background-color: white!important;
    }
    .btn-2-default {
        background-color: #f8ff00!important;
    }
    .btn-3-default {
        background-color: white!important;
    }
    .btn-4-default {
        background-color: #f8ff00!important;
    }
    .navbar {
        display: none;
    }
    .navbar-container {
        position: fixed;
        width: 100%;
        height: 36vh;
        display: none;
    }
    .navbar-container.open {
        right: 0;
        bottom: 0;
        opacity: 1;
        height: 36vh;
        z-index: 9;
        display: flex;
        flex-direction: column;
        background-color: #f8ff00;
        box-shadow: 0px -10px 15px silver;
    }
    .start-animation-mobile-menu{
        animation: startanimation 1s ease-out forwards;
    }

    .back-animation-mobile-menu{
        animation: backmobilemenuanimation 1s ease-out forwards;
    }
    @keyframes backmobilemenuanimation {
        0% {
        }
        100% {
            width: 100vw;
            height: 0vh;
        }
    }
    @keyframes startanimation {
        0% {
            width: 100vw;
            height: 0vh;
        }
        100% {
            height: 36vh;
            width: 100vw;
        }
    }
    .animation-container{
        height: 80vh !important;
        width: 100%;
        padding: 0% 0% 0% 0% !important;
        background-color: inherit;
    }
    .fa-times:before {
        content: url("./images/close2.svg") !important;
    }
    .fa-bars:before{
        content: url("./images/Menu-37.svg") !important;
    }
    .fas{
        background-color: transparent;
    }
}

[class^="btn-"] { /* Selector for all classes starting with "btn-" */
    text-transform: uppercase;
    font-size: 2.7vmin;
    font-family: 'zirkon','PT Sans', sans-serif;
    font-weight: bold;
    text-align: left;
    padding-left: 4%  ;
    color: black;
    transition: all 2s ease-out;
}
.btn-expanded {
    width: 50vw;
    height: 100%;
    background-color: #f8ff00;
    margin: 0% 0% 0% 0%;
    border: inherit;
    font-family: 'zirkon','PT Sans', sans-serif;
    font-weight: bold;
    color: black;
    transition: all 2s ease-out;
    order: 1;
}
.btn-1-collapsed {
    width: 25vw;
    height: 100%;
    background-color: #f8ff00;
    color: black;
    margin: 0;
    border: inherit;
    -webkit-animation: btn-1-color 5s infinite;
    animation: btn-1-color 5s infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    order: 0;
    transition: all 2s ease-out;
    animation: homeButtonAnimation 2s ease-out forwards;
}
@keyframes homeButtonAnimation {
    0% {
        width: 25vw;
    }
    100% {
        width: 0vw;
    }
}

.btn-2-collapsed {
    width: 25vw;
    height: 100%;
    background-color: #f8ff00;
    margin: 0;
    border: inherit;
    font-family: 'zirkon','PT Sans', sans-serif;
    font-weight: bold;
    -webkit-animation: btn-2-color 5s infinite;
    animation: btn-2-color 5s infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    order:3;
    color: black;
    transition: all 2s ease-out;
}
.btn-3-collapsed {
    width: 25vw;
    height: 100%;
    background-color: #f8ff00;
    margin: 0;
    border: inherit;
    font-family: 'zirkon','PT Sans', sans-serif;
    font-weight: bold;
    -webkit-animation: btn-3-color 5s infinite;
    animation: btn-3-color 5s infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    order:4;
    color: black;
    transition: all 2s ease-out;
}
.btn-4-collapsed {
    width: 25vw;
    height: 100%;
    background-color: #f8ff00;
    margin: 0;
    border: inherit;
    font-family: 'zirkon','PT Sans', sans-serif;
    font-weight: bold;
    -webkit-animation: btn-4-color 5s infinite;
    animation: btn-4-color 5s infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    order:5;
    color: black;
    transition: all 2s ease-out;
}
.btn-5-collapsed {
    order: 6;
}

.btn-1-default {
    width: 25vw;
    height: 100%;
    background-color: #f8ff00;
    margin: 0;
    border: inherit;
    font-family: 'zirkon','PT Sans', sans-serif;
    font-weight: bold;
    -webkit-animation: btn-1-color 5s infinite;
    animation: btn-1-color 5s infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    color: black;
    transition: all 2s ease-out;
}
@keyframes homeButtonBackAnimation {
    0% {
        width: 0vw;
    }
    100% {
        width: 25vw;
    }
}
.btn-2-default {
    width: 25vw;
    height: 100%;
    background-color: #fbff8a;
    margin: 0;
    border: inherit;
    font-family: 'zirkon','PT Sans', sans-serif;
    font-weight: bold;
    transition: inherit;
    -webkit-animation: btn-2-color 5s infinite;
    animation: btn-2-color 5s infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    color: black;
    transition: all 2s ease-out;

}
.btn-3-default {
    width: 25vw;
    height: 100%;
    background-color: #fdffbc;
    margin: 0;
    border: inherit;
    font-family: 'zirkon','PT Sans', sans-serif;
    font-weight: bold;
    transition: inherit;
    -webkit-animation: btn-3-color 5s infinite;
    animation: btn-3-color 5s infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    color: black;
    transition: all 2s ease-out;

}
.btn-4-default {
    width: 25vw;
    height: 100%;
    background-color: #feffe1;
    margin: 0;
    border: inherit;
    font-family: 'zirkon','PT Sans', sans-serif;
    font-weight: bold;
    transition: inherit;
    -webkit-animation: btn-4-color 5s infinite;
    animation: btn-4-color 5s infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    transition: all 2s ease-out;
}
.btn-5-default {
    color: black;
}
#closeButton {
    display: none;
    min-height: 5vh;
    min-width: 5vh;
    background-image: url("./images/close2.svg");
    background-size: cover;
    z-index: 999;
    position: fixed;
    left: calc(50vw - 8vh);
}
#topButton {
    display: none;
    min-height: 7vh;
    min-width: 7vh;
    background-image: url("./images/arrow_up.svg");
    background-size: cover;
    z-index: 99;
    position: fixed;
    left: calc(50vw - 8vh);
    bottom: 1vh;
}

