a{
    font-family: 'zirkon','PT Sans', sans-serif;
}
a.smallFont{
    font-family: 'GT-Zirkon-Book','PT Sans', sans-serif;
}
div.support-content {
    margin-top: 10%;
    max-width: 75%;
    margin-bottom: 10%;
}
div.support-title {
    position: relative;
    font-family: 'zirkon','PT Sans', sans-serif;
    font-size: 2.7vmin;
    padding: 10% 0% 1% 5%;
    font-weight: bold;
}
div.donation-highlight-text {
    font-family: 'zirkon','PT Sans', sans-serif;
    font-size: 2.7vmin;
    padding: 1% 0% 1% 5%;
    font-weight: bold;
}
div.donation-text{
    font-family: 'GT-Zirkon-Book','PT Sans', sans-serif;
    font-size: 2.2vmin;
    padding: 1% 0% 1% 5%;
    margin-bottom: 10%;
    line-height: 1.3;
}
div.support-title:after {
    content: "";
    display: block;
    height: 0.75vh;
    width: 100%;
    background: url("../images/border_line.svg") center center/cover no-repeat;
}
div.donationData-title {
    font-family: 'zirkon','PT Sans', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 2.7vmin;
    position: relative;
    border-bottom: solid black;
    margin: 1% 0% 1% 5%;

}
div.donationData-text {
    font-family: 'GT-Zirkon-Book','PT Sans', sans-serif;
    padding: 1% 0% 1% 5%;
    font-size: 2.2vmin;
    line-height: 1.3;
}
div.donor-pic-container {
    position: relative;
    margin: 1% 15% 1% 5%;
}
img.donor-image {
    margin: 0% 5% 0% 0%;
    object-fit: cover;
    max-width: 25%;
}
img.allsupporters-image {
    max-width: 25%;
    margin-right: 5%;
    margin-bottom: 5%;
}
div.allsupport-container {
    position: relative;
    margin: 0% 0% 10% 5%;
}
div.donor-text-container{
    position: relative;
    margin: 1% 15% 1% 5%;
}
span.donor-text {
    font-family: 'GT-Zirkon-Book','PT Sans', sans-serif;
    font-size: 2.2vmin;
    line-height: 1.3;
}
.supportData-highlight {
    font-family: 'zirkon','PT Sans', sans-serif;
    font-weight: bold;
    font-size: 2.7vmin;
    margin-bottom: 1%;
}
.supportData-text {
    font-family: 'GT-Zirkon-Book','PT Sans', sans-serif ;
    font-size: 2.2vmin;
    line-height: 1.3;
    margin: 1% 0% 1% 0%;
    width: 100%;
}
@media only screen and (max-width: 768px) {
    img.donor-image {
        width: 100%;
    }
    div.support-title {
        margin-top: 5%;
        font-size: 2.5vmax!important;
    }
    #aboutStart {
        mask-image: linear-gradient(to top,transparent 0%, #f8ff00 5%, #f8ff00 90%, transparent 100%)!important;
    }

    span.donor-text {
        font-size: 2.2vmax!important;
    }
    .supportData-highlight {
        font-size: 2.5vmax!important;
        margin-bottom: 2% !important;
    }
    .supportData-text {
        font-size: 2.2vmax!important;
        line-height: 1.2!important;
        margin-top: 1% !important;
    }

    div.donationData-title {
        font-size: 2.5vmax!important;
    }
    div.firstDonationData-title {
        font-size: 2.5vmax!important;
    }
    div.donationData-text {
        font-size: 2.2vmax!important;
    }
    div.donation-title {
        font-size: 2.5vmax!important;
    }
    div.donation-highlight-text {
        font-size: 2.5vmax!important;
    }
    div.donation-text{
        font-size: 2.2vmax!important;
    }
    div.support-content {
        margin-top: 20%!important;
        margin-bottom: 30%!important;
    }
    a{
        font-size: 2.5vmax !important;
    }
    img.allsupporters-image {
        max-width: 45%;
        margin-right: 5%;
        margin-bottom: 5%;
    }
    a.smallFont{
        font-size: 2.2vmax!important;
    }
}

