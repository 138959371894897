div#root{
    min-height: 100vh;
}
@font-face {
    font-family: zirkon;
    src: url("../fonts/GT-Zirkon-Bold.otf");
    font-weight: bold;
}
@keyframes btn-1-color {
    0% {
        background: #f8ff00;
    }
    25% {
        background-color: #fbff8a;
    }
    50% {
        background: #fdffbc;
    }
    75% {
        background: #feffe1;
    }
    100% {
        background: #fff
    }
}
@keyframes btn-2-color {
    0% {
        background: #fff;
    }
    25% {
        background-color: #fbff8a;
    }
    50% {
        background: #f8ff00;
    }
    75% {
        background: #fdffbc;
    }
    100% {
        background: #feffe1
    }

}
@keyframes btn-3-color {
    0% {
        background: #fff;
    }
    25% {
        background-color: #feffe1;
    }
    50% {
        background: #fdffbc;
    }
    75% {
        background: #fbff8a;
    }
    100% {
        background: #f8ff00;
    }

}
@keyframes btn-4-color {
    0% {
        background: #feffe1;
    }
    25% {
        background-color: #f8ff00;
    }
    50% {
        background: #fdffbc;
    }
    75% {
        background: #fbff8a;
    }
    100% {
        background: #ffffff;
    }
}

.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 18px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
}